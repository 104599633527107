import { FC, ReactNode, memo } from "react";

import {
  Box,
  TableBody,
  Table as MuiTable,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import styles from "./styles";

export type Cell = {
  name: string;
  width: string;
};
export interface ITableProps {
  fields: Array<any>;
  headerCells: Array<Cell>;
  renderRow: (field: any, index: number) => ReactNode;
  renderHeaderCell: (cell: Cell) => ReactNode;
}

const Table: FC<ITableProps> = ({
  fields,
  headerCells,
  renderRow,
  renderHeaderCell,
}) => {
  return (
    <Box sx={styles.wrapper}>
      <TableContainer sx={{ height: 300 }}>
        <MuiTable stickyHeader sx={styles.table}>
          <TableHead>
            <TableRow>
              {headerCells.map((cell) => renderHeaderCell(cell))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(fields || []).map((field, index) => renderRow(field, index))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Box>
  );
};

export default memo(Table);
