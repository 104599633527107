import { FC, memo } from "react";
import { Box, Typography } from "@mui/material";
import { ISellerToken } from "../../../store/seller-tokens-store";

interface ITableHeader {
  data: ISellerToken;
}

const tokenFieldNames = [
  "sellerToken",
  "sellerId",
  "sellerApiKey",
  "sellerIdPerfomance",
  "sellerSecretPerfomance",
];

const SellerTokenData: FC<ITableHeader> = ({ data }) => {
  const tokens = Object.fromEntries(
    Object.entries(data).filter(([key]) => tokenFieldNames.includes(key))
  );

  return (
    <Box sx={{ wordBreak: "break-all", minWidth: "300px" }}>
      {Object.keys(tokens).map((key) => {
        const value = tokens[key];
        return value ? (
          <Box key={key}>
            <Typography component="span">{key}: </Typography>
            <Typography component="span" variant="caption">
              {tokens[key]}
            </Typography>
          </Box>
        ) : null;
      })}
    </Box>
  );
};

export default memo(SellerTokenData);
