import { FC, memo } from "react";
import { TextField, Stack, Paper, Grid, Button } from "@mui/material";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../yup-extended";
import MuiFormSelect from "../../form-controls/mui-form-select";
import { CurrencyType, MarketplacesType } from "../../../types";
import { ISellerToken } from "../../../store/seller-tokens-store";

interface IAddFieldForm {
  onAdd: (data: ISellerToken) => void;
  onCancel: () => void;
  defaultData?: ISellerToken;
}

const MP_TYPES = [
  { value: "WB", label: "WB" },
  { value: "OZON", label: "OZON" },
];

const CURRENCY_TYPES = [
  { value: "RUR", label: "RUR" },
  { value: "BYN", label: "BYN" },
  { value: "KGS", label: "KGS" },
];

type AddSellerTokenFormValues = Omit<
  ISellerToken,
  "id" | "isExpired" | "expiryDate"
>;

const AddFieldForm: FC<IAddFieldForm> = ({ onAdd, onCancel, defaultData }) => {
  const schema = yup.object({
    name: yup.string().nullable(),
    description: yup.string().nullable(),
    marketplaceType: yup
      .string()
      .oneOf<MarketplacesType>(["WB", "OZON"])
      .required(),
    sellerToken: yup.string().when("marketplaceType", {
      is: "WB",
      then: (schema) => schema.required(),
    }),
    currency: yup
      .string()
      .oneOf<CurrencyType>(["RUR", "BYN", "KGS"])
      .nullable(),
    taxRate: yup.string().nullable(),
    vat: yup.string().nullable(),
    sellerId: yup.string().when("marketplaceType", {
      is: "OZON",
      then: (schema) => schema.required(),
    }),
    sellerApiKey: yup.string().when("marketplaceType", {
      is: "OZON",
      then: (schema) => schema.required(),
    }),
    sellerIdPerfomance: yup.string().when("marketplaceType", {
      is: "OZON",
      then: (schema) => schema.required(),
    }),
    sellerSecretPerfomance: yup.string().when("marketplaceType", {
      is: "OZON",
      then: (schema) => schema.required(),
    }),
  });

  const methods = useForm<AddSellerTokenFormValues>({
    resolver: yupResolver<AddSellerTokenFormValues>(schema),
    defaultValues: {
      name: defaultData?.name || undefined,
      description: defaultData?.description || undefined,
      marketplaceType: defaultData?.marketplaceType || "WB",
      sellerToken: defaultData?.sellerToken || "",
      currency: defaultData?.currency || undefined,
      taxRate: defaultData?.taxRate || undefined,
      sellerId: defaultData?.sellerId || undefined,
      sellerApiKey: defaultData?.sellerApiKey || undefined,
      sellerIdPerfomance: defaultData?.sellerIdPerfomance || undefined,
      sellerSecretPerfomance: defaultData?.sellerSecretPerfomance || undefined,
      vat: defaultData?.vat || undefined,
    },
  });

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<AddSellerTokenFormValues> = (data) => {
    if (defaultData?.id) {
      onAdd({ id: defaultData.id, ...data });
    } else {
      onAdd(data);
    }

    reset();
  };

  const mpValue: MarketplacesType = useWatch({
    control,
    name: "marketplaceType",
  });

  return (
    <Paper sx={{ p: 2, borderRadius: "8px" }} elevation={2}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          gap: "16px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item sm={3}>
            <MuiFormSelect
              label="Маркетплейс"
              options={MP_TYPES}
              error={errors?.marketplaceType}
              variant="standard"
              name="marketplaceType"
              control={control}
            />
          </Grid>
          {mpValue === "WB" && (
            <Grid item sm={9}>
              <TextField
                label="API ключ"
                variant="standard"
                fullWidth
                error={!!errors?.sellerToken}
                helperText={errors?.sellerToken?.message}
                {...register("sellerToken")}
              />
            </Grid>
          )}

          {mpValue === "OZON" && (
            <Grid item sm={3}>
              <TextField
                label="sellerId"
                variant="standard"
                fullWidth
                error={!!errors?.sellerId}
                helperText={errors?.sellerId?.message}
                {...register("sellerId")}
              />
            </Grid>
          )}
          {mpValue === "OZON" && (
            <Grid item sm={6}>
              <TextField
                label="sellerIdPerfomance"
                variant="standard"
                fullWidth
                error={!!errors?.sellerIdPerfomance}
                helperText={errors?.sellerIdPerfomance?.message}
                {...register("sellerIdPerfomance")}
              />
            </Grid>
          )}
          {mpValue === "OZON" && (
            <Grid item sm={6}>
              <TextField
                label="sellerSecretPerfomance"
                variant="standard"
                fullWidth
                error={!!errors?.sellerSecretPerfomance}
                helperText={errors?.sellerSecretPerfomance?.message}
                {...register("sellerSecretPerfomance")}
              />
            </Grid>
          )}
          {mpValue === "OZON" && (
            <Grid item sm={6}>
              <TextField
                label="sellerApiKey"
                variant="standard"
                fullWidth
                error={!!errors?.sellerApiKey}
                helperText={errors?.sellerApiKey?.message}
                {...register("sellerApiKey")}
              />
            </Grid>
          )}
          <Grid item sm={3}>
            <TextField
              label="№ каб."
              variant="standard"
              fullWidth
              error={!!errors?.name}
              helperText={errors?.name?.message}
              {...register("name")}
            />
          </Grid>
          <Grid item sm={3}>
            <MuiFormSelect
              label="Валюта"
              options={CURRENCY_TYPES}
              error={errors?.currency}
              variant="standard"
              name="currency"
              control={control}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              label="НДС,%"
              variant="standard"
              fullWidth
              error={!!errors?.vat}
              helperText={errors?.vat?.message}
              type="string"
              {...register("vat")}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              label="Ставка налога,%"
              variant="standard"
              fullWidth
              error={!!errors?.taxRate}
              helperText={errors?.taxRate?.message}
              type="string"
              {...register("taxRate")}
            />
          </Grid>
          <Grid item sm={12}>
            <TextField
              label="Описание"
              variant="standard"
              fullWidth
              error={!!errors?.description}
              helperText={errors?.description?.message}
              {...register("description")}
            />
          </Grid>
        </Grid>

        <Stack gap={2} direction="row" mt={2}>
          <Button onClick={onCancel} variant="text">
            Отменить
          </Button>

          <Button type="submit" variant="contained">
            {Boolean(defaultData) ? "Редактировать" : "Добавить"}
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};

export default memo(AddFieldForm);
