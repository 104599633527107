import { FC, useEffect, useRef } from "react";
import { autorun } from "mobx";
import { useSnackbar } from "notistack";
import useStore from "./hooks/use-store";
import { NotificationType } from "./store/notifications-store";
import { observer } from "mobx-react-lite";
import { Button, Stack, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

const USER_NOTIFICATIONS_TYPE: Record<string, NotificationType> = {
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
};

const Notifier: FC = () => {
  const { notificationStore, userNotificationsStore } = useStore();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const usedIds = useRef<Array<string>>([]);
  const { notifications } = notificationStore;
  const { userNotifications } = userNotificationsStore;
  const { push } = useHistory();

  const goNotificationLink = (link?: string) => {
    push(link || "/settings");
    closeSnackbar();
  };

  useEffect(() => {
    if (userNotifications?.personal) {
      userNotifications.personal.forEach((notification) => {
        notificationStore.addNotification({
          text: (
            <Stack alignItems="flex-start">
              <Typography variant="body1">{notification.title}</Typography>
              <Typography variant="body2">{notification.message}</Typography>
              {notification.buttonLink && (
                <Button
                  sx={{ mt: 2 }}
                  variant="outlined"
                  color="inherit"
                  onClick={() => goNotificationLink(notification.buttonLink)}
                >
                  {notification.buttonText || "Подробнее"}
                </Button>
              )}
            </Stack>
          ),
          type: USER_NOTIFICATIONS_TYPE[notification.level],
          options: { hideIconVariant: true },
        });
      });
    }
  }, [userNotifications]);

  useEffect(() => {
    autorun(() => {
      notifications.forEach(({ type, text, options, id }) => {
        if (!usedIds.current.includes(id)) {
          enqueueSnackbar(text, { variant: type, ...options });

          usedIds.current.push(id);
        }
      });
    });
  }, [notifications, enqueueSnackbar]);

  return null;
};

export default observer(Notifier);
