import { Theme, SxProps } from "@mui/material/styles";

const styles: Record<string, SxProps<Theme>> = {
  wrapper: {
    width: "100%",
    minHeight: "500px",
    position: "relative",
  },
  addForm: {
    position: "fixed",
    width: "calc(100% - 250px)",
    bottom: "24px",
  },
};

export default styles;
