import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { observer } from "mobx-react-lite";
import {
  Backdrop,
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

import { IUser } from "../../store/admin-store";
import styles from "./styles";
import locale from "./locale";
import AddFieldForm from "./components/add-field-form";
import { useStore } from "../../hooks";
import SellerTokenData from "./components/sellet-token-data";
import Loader from "../loader/loader";
import { ISellerToken } from "../../store/seller-tokens-store";
import Table, { Cell } from "../table/table";

interface ISellerTokensProps {
  user?: IUser | null;
}

export interface ISellerTokensForm {
  items: Array<ISellerToken>;
}

const SellerTokens: FC<ISellerTokensProps> = () => {
  const {
    userStore: { userInfo },
    sellerTokensStore: {
      fetchSellerTokens,
      addSellerToken,
      editSellerToken,
      deleteSellerToken,
      sellerTokens,
      isLoading,
    },
    popupStore,
  } = useStore();
  const [showAddForm, setShowAddForm] = useState(false);
  const [editData, setEditData] = useState<ISellerToken | undefined>();

  const headerCells = useMemo(() => locale.headerCells, []);

  const onAdd = useCallback(
    (data: ISellerToken) => {
      const filteredData = Object.entries(data)
        .filter(([key, value]) => !!value)
        .reduce((acc, [key, value]) => {
          //@ts-ignore
          acc[key] = value;
          return acc;
        }, {}) as ISellerToken;

      if (Boolean(editData)) {
        editSellerToken(filteredData);
      } else {
        addSellerToken(filteredData);
      }

      setShowAddForm(false);
    },
    [editData]
  );

  const toggleShowAdd = useCallback(() => {
    if (!showAddForm) {
      setEditData(undefined);
      setShowAddForm(true);
    }
  }, [showAddForm]);

  const onCancelAdd = useCallback(() => {
    setShowAddForm(false);
  }, []);

  const onRemoveField = (data: ISellerToken) => {
    if (data.id) {
      deleteSellerToken(data.id, data.marketplaceType);
    }
  };

  const onEditField = (id: number) => {
    const editToken = sellerTokens?.find((token) => token.id === id);
    setEditData(editToken);
    setShowAddForm(true);
  };

  const onShowTokens = (data: ISellerToken) => {
    popupStore.openPopup({
      props: { size: "md" },
      contentProps: { data },
      content: SellerTokenData,
    });
  };

  const renderHeaderCell = useCallback(
    (cell: Cell) => (
      <TableCell key={cell.name} align="left" width={cell.width}>
        {cell.name === "edit" ||
        cell.name === "delete" ||
        cell.name === "showTokens"
          ? ""
          : cell.name}
      </TableCell>
    ),
    []
  );

  const renderRow = useCallback(
    (field: ISellerToken, index: number) => {
      return (
        <TableRow
          key={index}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
            backgroundColor: field.isExpired ? "#f5d5d3" : undefined,
          }}
        >
          <TableCell align="left">{field.marketplaceType}</TableCell>
          <TableCell component="th" scope="row">
            {field.name}
          </TableCell>
          <TableCell align="left">{field.currency}</TableCell>
          <TableCell align="left">{field.vat}</TableCell>
          <TableCell align="left">{field.taxRate}</TableCell>
          <TableCell>{field.description || ""}</TableCell>
          <TableCell>
            <Stack direction="row" gap={2} alignItems="center">
              <Typography>{field.expiryDate || ""}</Typography>
              {field.isExpired && (
                <Chip variant="filled" label="Истекает" color="error" />
              )}
            </Stack>
          </TableCell>
          {field.id && (
            <TableCell align="left">
              <IconButton size="small" onClick={() => onShowTokens(field)}>
                <VpnKeyIcon color="primary" />
              </IconButton>
            </TableCell>
          )}
          {field.id && (
            <TableCell align="left">
              <IconButton size="small" onClick={() => onEditField(field.id!)}>
                <EditIcon color="action" />
              </IconButton>
            </TableCell>
          )}
          {field.id && (
            <TableCell align="left">
              <IconButton size="small" onClick={() => onRemoveField(field)}>
                <DeleteIcon color="action" />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      );
    },
    [onShowTokens, onEditField, onRemoveField]
  );

  useEffect(() => {
    if (!sellerTokens) {
      fetchSellerTokens();
    }
  }, [sellerTokens]);

  return (
    <Box sx={styles.wrapper}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h6">{userInfo?.fio}</Typography>
        <Box>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={toggleShowAdd}
            sx={{ mr: 2 }}
          >
            Добавить
          </Button>
        </Box>
      </Stack>

      {!sellerTokens?.length && !isLoading && (
        <Typography variant="caption" mt={4}>
          У вас пока нет токенов. Нажмите кнопку добавить и заполните поля для
          создания вашего первого токена
        </Typography>
      )}
      {Boolean(sellerTokens?.length) && (
        <Table
          headerCells={headerCells}
          fields={sellerTokens as ISellerToken[]}
          renderHeaderCell={renderHeaderCell}
          renderRow={renderRow}
        />
      )}

      <Loader isLoading={isLoading} />

      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={showAddForm}
      >
        <Box sx={styles.addForm} id="add-form">
          {showAddForm && (
            <AddFieldForm
              onAdd={onAdd}
              onCancel={onCancelAdd}
              defaultData={editData}
            />
          )}
        </Box>
      </Backdrop>
    </Box>
  );
};

export default observer(SellerTokens);
