import { FC, useCallback } from "react";

import { Button, Link, Stack, Toolbar, Typography } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import TelegramIcon from "@mui/icons-material/Telegram";

import logo from "./logo.png";
import Marketplaces from "../marketplaces/marketplaces";
import { useStore } from "../../hooks";
import { observer } from "mobx-react-lite";
import Profile from "./components/profile/profile";
import { useHistory, useLocation } from "react-router-dom";
import BarNotifications from "./components/bar-notifications";

interface IPropsAppBar {
  open: boolean;
  toggleDrawer: () => void;
}

const AppBar: FC<IPropsAppBar> = () => {
  const {
    userStore: { isAdmin },
  } = useStore();
  const { pathname } = useLocation();
  const showMarketplaces = pathname.includes("dashboard");

  const { push } = useHistory();

  const goHome = useCallback(() => {
    push("/");
  }, [push]);

  return (
    <MuiAppBar
      position="absolute"
      sx={{
        backgroundColor: (theme) =>
          isAdmin ? theme.palette.primary.main : theme.palette.grey[100],
        boxShadow: "none",
      }}
    >
      <Toolbar
        sx={{
          paddingLeft: "10px !important",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Link
          component="img"
          src={logo}
          alt="Logo"
          style={{ width: "200px" }}
          onClick={goHome}
        />

        <Stack
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          flexDirection="row"
        >
          {!isAdmin && showMarketplaces && <Marketplaces />}
          {isAdmin && (
            <Typography ml={3} variant="h5">
              Админка
            </Typography>
          )}

          <Stack direction="row" justifyContent="flex-end" flexGrow={1}>
            {!isAdmin && (
              <Button
                variant="contained"
                size="small"
                startIcon={<TelegramIcon />}
                href="https://t.me/effstepsalesdir"
                target="_blank"
                sx={{ mr: 1 }}
              >
                Техподдержка
              </Button>
            )}
            {!isAdmin && (
              <Button
                variant="contained"
                size="small"
                href="https://t.me/effstepsalesdir"
                target="_blank"
                sx={{ mr: 1 }}
              >
                Хотелки
              </Button>
            )}
          </Stack>
          <BarNotifications />
          <Profile />
        </Stack>
      </Toolbar>
    </MuiAppBar>
  );
};

export default observer(AppBar);
