import { makeAutoObservable } from "mobx";
import { RootStore } from "./root-store";
import { CurrencyType, MarketplacesType } from "../types";

export interface ISellerToken {
  id?: number;
  sellerToken?: string;
  marketplaceType: MarketplacesType;
  name?: string | null;
  currency?: CurrencyType | null;
  taxRate?: string | null;
  description?: string | null;
  sellerId?: string | null;
  sellerApiKey?: string | null;
  sellerIdPerfomance?: string | null;
  sellerSecretPerfomance?: string | null;
  expiryDate?: string | null;
  isExpired?: boolean | null;
  vat?: string | null;
}

export default class UserStore {
  public sellerTokens: Array<ISellerToken> | null = null;

  public isLoading: boolean = false;

  protected rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  private setLoading = (value: boolean) => {
    this.isLoading = value;
  };

  public setSellerTokens = (tokens: Array<ISellerToken>) => {
    this.sellerTokens = tokens;
  };

  public fetchSellerTokens = async (): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest<Array<ISellerToken>>("getSellerTokens")
      .then((data) => {
        this.setSellerTokens(data);
        this.setLoading(false);
      })
      .catch(() => this.setLoading(false));
  };

  public addSellerToken = async (tokenData: ISellerToken): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest<Array<ISellerToken>>(
        "addSellerToken",
        undefined,
        tokenData
      )
      .then(async (data) => {
        this.setSellerTokens(data);
        this.rootStore.notificationStore.addNotification({
          text: "Токен селлера успешно добавлен!",
          options: {
            autoHideDuration: 2000,
          },
          type: "success",
        });
        this.setLoading(false);
      })
      .catch(() => this.setLoading(false));
  };

  public editSellerToken = async (tokenData: ISellerToken): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest<Array<ISellerToken>>(
        "editSellerToken",
        undefined,
        tokenData
      )
      .then(async (data) => {
        this.setSellerTokens(data);
        this.rootStore.notificationStore.addNotification({
          text: "Токен селлера успешно изменен!",
          options: {
            autoHideDuration: 2000,
          },
          type: "success",
        });
        this.setLoading(false);
      })
      .catch(() => this.setLoading(false));
  };

  public deleteSellerToken = async (
    id: number,
    marketplaceType: MarketplacesType
  ): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest<Array<ISellerToken>>("deleteSellerToken", undefined, {
        id,
        marketplaceType,
      })
      .then(async (data) => {
        this.setSellerTokens(data);
        this.rootStore.notificationStore.addNotification({
          text: "Токен селлера успешно удален!",
          options: {
            autoHideDuration: 2000,
          },
          type: "success",
        });
        this.setLoading(false);
      })
      .catch(() => this.setLoading(false));
  };
}
